<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-07 10:27:56
 * @LastEditTime: 2021-06-18 16:14:46
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="other-info-list">
    <LogoTitle msg="其它信息"></LogoTitle>
    <div class="list">
      <NewsCard
        source="OtherInfo"
        v-for="item in list"
        :info="item"
        :key="item.id"
      ></NewsCard>
    </div>
    <div class="sljr-pagination">
      <SinglePagination
        :total="total"
        v-if="total <= pageSize"
      ></SinglePagination>
      <el-pagination
        :hide-on-single-page="isHide"
        :page-size="pageSize"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      pageSize: 10,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      list: ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    /**
* @description: 机构动态列表
* @param  {categoryId: '1376793891675738114',categoryType,pageNo,pageSize}
* @return {*}
*/
    getList () {
      let params = {
        categoryId: '1376793891675738114',
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
        this.list.forEach(item => {
          if (item.contentUrl) {
            //截取文件名
            let fileName = item.contentUrl.split('/').pop();
            console.log('其他信息-filieName：' + fileName)
            item.fileName = fileName;
          }
          if (item.contentImg) {
            item.contentImg = JSON.parse(item.contentImg)
          }
        })
        console.log(this.list)
      })
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList()
      document.body.scrollIntoView()
    }
  },
}
</script>

<style scoped lang='scss'>
</style>